@mixin app-result-page {
  app-result-page {
    display: block;
    width: pxtorem(1230);
    .head {
      h1 {
        font-size: pxtorem(48);
        line-height: pxtorem(71);
        padding: 0 0 0 pxtorem(32);
      }
    }
    .body {
      background-color: #ececec;
      box-shadow: 0 pxtorem(4) pxtorem(4) rgba(0, 0, 0, 0.25);
      border-radius: pxtorem(10);
      border: pxtorem(1) solid rgba(0, 0, 0, 0.5);
      padding: 2rem;
      .row {
        &.g-0 {
          margin-bottom: pxtorem(10);
          &.transaction-status {
            margin-bottom: pxtorem(11);
            img {
              width: pxtorem(40);
            }
            h4 {
              padding-left: pxtorem(13);
              font-family: inherit;
              font-size: pxtorem(31);
              font-weight: bold;
              line-height: pxtorem(44);
              &.text-red {
                color: var(--pp-red);
              }
              &.text-green {
                color: var(--pp-green);
              }
              &.text-gold {
                color: var(--pp-gold);
              }
            }
          }
          h4 {
            font-family: inherit;
            font-size: pxtorem(25);
            line-height: pxtorem(37);
            font-weight: 400;
            word-break: break-word;
            &.text-red {
              color: var(--pp-red);
            }
            &.text-green {
              color: var(--pp-green);
            }
            &.text-gold {
              color: var(--pp-gold);
            }
          }
        }
      }
    }

    .footer {
      padding: pxtorem(24) 0;

      .grid {
        width: 66.5%;
        // row-gap: pxtorem(8);
        gap: var(--pp-gap, pxtorem(6));
      }

      .btn-primary {
        @include button-variant(
          $primary,
          //background
          $primary,
          //border
          $white,
          //color
          $primary-hover,
          //hover background
          $primary-hover,
          //hover border
          $white,
          //hover color
          $primary-hover,
          // active background
          $primary-hover,
          //active border
          $white,
          //active color
        );
        @include button-size(0.2rem, 0.5rem, 1.6rem, 0.3125rem);
        font-family: inherit;
        transition: background-color 0.25s ease-out, color 0.25s ease-out;
        --pp-btn-line-height: 2.313rem;
      }
    }
  }

  @include media-breakpoint-down(xxl) {
    app-result-page {
      width: 79%;
    }
  }
  @include media-breakpoint-down(xl) {
    app-result-page {
      width: 78%;
    }
  }
  @include media-breakpoint-down(lg) {
    app-result-page {
      width: 77%;
    }
  }
  @include media-breakpoint-down(md) {
    app-result-page {
      display: block;
      width: 100%;
      .head {
        h1 {
          font-size: pxtorem(24);
          line-height: pxtorem(35);
          padding: 0 0 pxtorem(7) pxtorem(9);
        }
      }
      .body {
        background-color: #ececec;
        box-shadow: none;
        border-radius: pxtorem(5);
        border: pxtorem(1) solid #0a0a0a40;
        padding: pxtorem(16);
        .row {
          &.g-0 {
            margin-bottom: pxtorem(16);
            &.transaction-status {
              margin-bottom: pxtorem(32);
              img {
                width: pxtorem(25);
              }
              h4 {
                padding-left: pxtorem(9);
                font-family: inherit;
                font-size: pxtorem(20);
                font-weight: 700;
                line-height: pxtorem(21);
              }
            }
            h4 {
              font-family: inherit;
              font-size: pxtorem(18);
              line-height: pxtorem(18);
              font-weight: 400;
              word-break: break-word;
            }
          }
        }
      }

      .footer {
        padding: 1rem 0;

        .grid {
          width: 100%;
          row-gap: pxtorem(10);
        }

        .btn-primary {
          @include button-variant(
            $primary,
            //background
            $primary,
            //border
            $white,
            //color
            $primary-hover,
            //hover background
            $primary-hover,
            //hover border
            $white,
            //hover color
            $primary-hover,
            // active background
            $primary-hover,
            //active border
            $white,
            //active color
          );
          @include button-size(0.2rem, 0.5rem, 1.6rem, 0.3125rem);
          font-family: inherit;
          transition: background-color 0.25s ease-out, color 0.25s ease-out;
          --pp-btn-line-height: 2.313rem;
        }
      }
    }
  }
}
