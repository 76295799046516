// @import "functions";

//root
$prefix: "pp-";

//colors
$white: #ffffff;
$black: #000000;
$primary: #1779ba;
$primary-hover: #1779be;
$medium-gray: #cacaca;
$alert: #cc4b37;
$green: green;
$red: red;
$gold: gold;

$enable-rfs: false;
$enable-cssgrid: true;
$font-family-base: Circe-Regular;
$headings-margin-bottom: 0;
$input-placeholder-color: $medium-gray;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 640px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
);

//:root {
// --#{$prefix}Satoshi-Light: Satoshi-Light;
// --#{$prefix}Satoshi-Regular: Satoshi-Regular;
// --#{$prefix}Satoshi-Medium: Satoshi-Medium;
// --#{$prefix}Satoshi-Bold: Satoshi-Bold;
// --#{$prefix}Satoshi-Black: Satoshi-Black;
// --#{$prefix}content-wrapper: #{torem(1283)};
//--#{$prefix}font-sans-serif: Circe-Regular;
//--#{$prefix}body-font-family: Circe-Regular;
//}
