@import "functions";
@import "component_mixins";
//You can add global styles to this file, and also import other style files

//Colors
//P2P
$white: #ffffff;

//Fonts
@font-face {
  font-family: "Circe-Regular";
  src:
    url("../assets/fonts/Circe/Circe-Regular.otf") format("truetype"),
    url("../assets/fonts/Circe/Circe-Regular.eot") format("embedded-opentype");
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

///Foundation 6 switch
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 1.5rem;
  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .slider {
      background-color: var(--pp-primary);
      &:before {
        transform: translateX(1.5rem);
      }
    }
  }
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--pp-medium-gray);
    border-radius: 0.3125rem;
    transition: all 0.25s ease-out;
    &:before {
      position: absolute;
      content: "";
      height: 1rem;
      width: 1rem;
      left: 0.25rem;
      top: 0.25rem;
      border-radius: 0.3125rem;
      background-color: var(--pp-white);
      transition: all 0.25s ease-out;
    }
  }
}
///Foundation 6 switch

//Buttons
.btn-p2p {
  width: pxtorem(367);
  @include button-variant($primary, $primary, $white);
  @include button-size(pxtorem(8), pxtorem(16), pxtorem(30), pxtorem(5));

  //--pp-btn-font-family: ;
  --pp-btn-line-height: 32px;
  --pp-btn-font-weight: 400;
}

.btn-dropdown {
  width: pxtorem(65);
  @include button-variant(#f9fafc, #f9fafc, #46505b);
  @include button-size(pxtorem(16), pxtorem(8), pxtorem(20), pxtorem(5));

  //--pp-btn-font-family: ;
  --pp-btn-line-height: 23px;
  --pp-btn-font-weight: 400;
}

//Inputs
.form-control {
  display: block;
  height: pxtorem(55);
  width: 100%;
  padding: pxtorem(15) pxtorem(9);
  font-size: pxtorem(25);
  font-weight: 400;
  line-height: pxtorem(29);
  color: #000000;
  background-color: #f9fafc;
  background-clip: padding-box;
  border: pxtorem(1.5) solid #f3f4f8;
  border-radius: pxtorem(5);
  appearance: none;

  &:focus {
    border: pxtorem(1.5) solid #f3f4f8;
  }
  &::placeholder {
    font-family: $font-family-base;
    text-transform: none;
    // font-size: pxtorem(19);
  }
}

.err-msg {
  margin: 0.25rem 0 0 0;
  padding: 0;
  font-size: pxtorem(14);
  line-height: pxtorem(13);
  color: #cc4b37;
}

.err-input {
  border: pxtorem(3) solid #cc4b37;
  border-radius: pxtorem(5);
  background-image: none;
}

.grecaptcha-badge {
  width: 70px !important;
  overflow: hidden !important;
  transition: all 0.3s ease !important;
  left: 4px !important;
  &:hover {
    width: 256px !important;
  }
}

@media only screen and (max-width: 640px) {
  .form-control {
    height: pxtorem(45);
    font-size: pxtorem(20);
    line-height: pxtorem(23);
  }

  .btn-p2p {
    width: 100%;
  }
}

@include app-request-form();
@include app-result-page();
@include app-exception-page();
