@mixin app-request-form {
  app-request-form {
    display: block;
    width: pxtorem(950);

    .sender-block,
    .receiver-block {
      width: pxtorem(455);
      padding: 0 pxtorem(20) pxtorem(20);
      background-color: #c1dbf4;
      border-radius: pxtorem(10);
      box-shadow: pxtorem(2) pxtorem(2) pxtorem(6) rgba(0, 0, 0, 0.5),
        pxtorem(-2) pxtorem(-2) pxtorem(5) rgba(255, 255, 255, 0.5);
    }

    .sender-block > .header,
    .receiver-block > .header {
      align-items: center;
      h1 {
        display: block;
        font-size: pxtorem(24);
        font-style: normal;
        font-weight: 700;
        line-height: pxtorem(35);
        color: rgba(0, 0, 0, 0.7);
      }

      img {
        margin-left: auto;
        width: pxtorem(70);
      }
    }

    .sender-block > .body,
    .receiver-block > .body {
      overflow: hidden;
      // width: pxtorem(315);
      .form-field {
        height: pxtorem(100);
        label {
          margin: 0;
          padding: 0;
          font-style: normal;
          font-weight: 400;
          font-family: inherit;
          font-size: pxtorem(20);
          line-height: pxtorem(23);
          letter-spacing: -0.07em;
          color: #46505b;
        }
        input {
          margin: 0;
        }
      }
    }

    #PSPSenderBlock {
      margin: 0;
      padding: 0;
      width: 100vw;
      height: pxtorem(200);
      display: block;
      overflow: hidden;
    }

    #PSPReceiverBlock {
      margin: 0;
      padding: 0;
      width: 100vw;
      height: pxtorem(100);
      display: block;
      overflow: hidden;
    }

    #PSPReceiverBlockAmount {
      margin: 0;
      padding: 0;
      width: 100vw;
      height: pxtorem(300);
      display: block;
      overflow: hidden;
    }

    .app-receiver-amount {
      gap: 0 pxtorem(12);
      .btn-dropdown {
        width: pxtorem(91.2);
        @include button-variant(#f9fafc, #f9fafc, #46505b);
        @include button-size(pxtorem(15), pxtorem(1), pxtorem(25), pxtorem(5));

        //--pp-btn-font-family: ;
        --pp-btn-line-height: 1.563rem;
        --pp-btn-font-weight: 400;
      }
      //Inputs
      .form-control {
        text-align: right;
        margin: 0;
        width: 19.5rem;
        padding: pxtorem(15) pxtorem(9);
        &:readonly {
          background-color: #f9fafc;
          opacity: 1;
        }
      }
      .dropdown {
        button {
          margin: pxtorem(24) 0 0 0;
        }
        .dropdown-toggle:after {
          margin-left: -0.145em;
        }
        .dropdown-menu {
          cursor: pointer;
          --pp-dropdown-min-width: #{pxtorem(91.2)};
          --pp-dropdown-font-size: 1.5rem;
          --pp-dropdown-padding-y: 0.2rem;
        }
      }
    }
    .form-confirmation {
      margin-top: pxtorem(17);

      .terms {
        label {
          a {
            padding-left: pxtorem(4);
            font-family: inherit;
            font-style: normal;
            font-weight: 400;
            font-size: pxtorem(14);
            line-height: pxtorem(17);
            text-decoration: none;

            color: var(--pp-black);
            span {
              text-decoration: underline;
            }
          }
        }
      }

      button {
        margin-top: pxtorem(20);
      }
    }
  }

  @media only screen and (max-width: 640px) {
    app-request-form {
      width: 99.6%;
      .receiver-block {
        margin-top: pxtorem(18);
      }
      .sender-block,
      .receiver-block {
        width: 100%;
        padding: 0 pxtorem(20) 0 pxtorem(20);
        background-color: #c1dbf4;
        border-radius: pxtorem(10);
        box-shadow: 0 pxtorem(4) pxtorem(4) rgba(0, 0, 0, 0.25);
      }

      .sender-block > .header,
      .receiver-block > .header {
        h1 {
          display: block;
          margin-top: pxtorem(17);
          font-family: inherit;
          font-size: pxtorem(24);
          font-style: normal;
          font-weight: 700;
          line-height: pxtorem(35);
          color: rgba(0, 0, 0, 0.7);
        }

        img {
          margin-left: auto;
          width: pxtorem(50);
        }
      }

      .sender-block > .body,
      .receiver-block > .body {
        overflow: hidden;
        width: 100%;
        .form-field {
          height: pxtorem(100);
          label {
            margin: 0;
            padding: 0;
            font-style: normal;
            font-weight: 400;
            font-family: inherit;
            font-size: pxtorem(20);
            line-height: pxtorem(23);
            letter-spacing: -0.07em;
            color: #46505b;
          }
          input {
            margin: 0;
          }
        }
      }

      #PSPSenderBlock {
        margin: 0;
        padding: 0;
        width: 100%;
        height: pxtorem(200);
      }

      #PSPReceiverBlock {
        margin: 0;
        padding: 0;
        width: 100%;
        height: pxtorem(100);
      }

      #PSPReceiverBlockAmount {
        margin: 0;
        padding: 0;
        width: 100%;
        height: pxtorem(300);
      }

      .app-receiver-amount {
        gap: 0 pxtorem(9.5);
        .btn-dropdown {
          width: pxtorem(72);
          @include button-variant(#f9fafc, #f9fafc, #46505b);
          @include button-size(
            pxtorem(10),
            pxtorem(1),
            pxtorem(20),
            pxtorem(5)
          );

          //--pp-btn-font-family: ;
          --pp-btn-line-height: 1.438rem;
          --pp-btn-font-weight: 400;
        }
        //Inputs
        .form-control {
          height: pxtorem(45);
          width: 100%;
          font-size: pxtorem(20);
          line-height: pxtorem(23);
        }

        .dropdown {
          margin-left: auto;
          button {
            margin: pxtorem(24) 0 0 0;
          }
          .dropdown-menu {
            cursor: pointer;
            --pp-dropdown-min-width: pxtorem(65);
            --pp-dropdown-font-size: 1.25rem;
            --pp-dropdown-item-padding-x: 0.9rem;
          }
        }
      }

      .form-confirmation {
        width: 100%;
        margin-top: pxtorem(17);

        .terms-agreement-block {
          justify-content: center;
          .terms {
            padding-left: 0.25rem;
            label {
              a {
                padding-left: pxtorem(4);
                font-size: pxtorem(14);
                line-height: pxtorem(17);
                text-decoration: none;

                color: var(--pp-black);
              }
            }
          }
        }
        .err-msg {
          text-align: center;
        }
        button {
          width: 100%;
          margin-top: pxtorem(20);
        }
      }
    }
  }
}
